.ArticleInTherapyWrapper {
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgray;
    padding: 1rem 0;
}

.ArticleInTherapyWrapper:last-child {
    border-bottom: 1px solid lightgray;
}

.UpperPart {
    display: flex;
    justify-content: space-between;
}

.BottomPart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.IconStyle {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.InfoLabel {
    color: #00B2B1;
}

