.ComponentToPrintWrapper {
    margin: 4rem 4rem;
}

.DrNameAndDate {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #E2E8EC;
}

.DrName {
    font-weight: bold;
}