.ModalTitle {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.SettingsTitle {
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 20px;
}