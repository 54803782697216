.ArticleDescription {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.Title {
    font-size: 0.9rem;
    color: #A6A6A6;
}

.Text {
    font-size: 1rem;
    display: flex;
}
