.SearchItems {
    background-color: white;
    /*border-radius: 2.188rem;*/
    display: flex;
    flex-direction: column;
    z-index: 21;
    box-shadow: 0 0 0.625rem -0.25rem #535358;
    max-height: 30vh;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    border-bottom: 1px solid #00B2B1;
    border-left: 1px solid #00B2B1;
    border-right: 1px solid #00B2B1;
    position: absolute;
    width: 100%;
}

.SearchItemsDisplayNone {
    display: none;
}