.TherapyBoardWrapper {
    margin-top: 1rem;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    box-sizing: border-box;
}

.FormContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh;
}

.TherapyLeftPane {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 4rem;
}

.TherapyRightPane {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 4rem;
    position: relative;
}

.ArticlesSearchInput {
    position: relative;
    width: 100%;
}


.BottomButtonsWrapper {
    display: flex;
    justify-content: space-between;
    margin: 3rem;
}

.PrintButton {
    margin-right: 1rem;
}

#PrintButton {
    display: none;
}

@media screen and (min-width: 992px) {

    .TherapyBoardWrapper {
        margin-top: 2rem;
    }

    .FormContentWrapper {
        min-height: 70vh;
    }

    .TherapyLeftPane {
        border-right: 1px solid #E2E8EC;
    }

    #PrintButton {
        display: inline-block;
    }
}