.AuthPageContent {
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 1rem 10% 0;
    z-index: 2;
    align-items: center;
}

.AuthPageContent .Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    width: 100%;
}

.AuthPageContent .Form input {
    width: 90%;
    margin-top: 0.85rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 2%;
    font-size: 14px
}

.AuthPageLogo {
    cursor: pointer;
    width: 250px;
    height: auto;
}

.TopContainer {
    /*height: 250px;*/
    display: flex;
    flex-direction: column;
    padding: 5rem 1.8rem 1rem;
    align-items: center;
}

.Links {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    justify-content: center;
}

.Links p {
    font-size: 14px;
    color: #535358;
}

.Links a {
    color: #416caa;
    cursor: pointer;
}

.Links a:hover {
    text-decoration: underline;
}

.ErrorMessage {
    color: red;
    font-size: 12px;
    width: 90%;
    margin-top: 0.2rem;
}

.LoginPaneWrapper {
    width: 100%;
}

@media screen and (min-width: 992px){
    .LoginPaneWrapper {
        width: 30vw;
    }
}
