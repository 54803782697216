.FooterWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.Logo {
    display: none;
}

@media screen and (min-width: 768px) {
    .FooterWrapper {
        padding: 1rem 4rem;
        align-items: flex-start;
    }
}

@media screen and (min-width: 992px) {
    .FooterWrapper {
        height: 8rem;
    }
    .Logo {
        display: inline-block;
    }
}