.TherapyPage {
    background-color: #FBFBFB;
    padding: 0 1rem;
    position: relative;
    min-height: 100vh;
}

.UpperPartWrapper {
    padding-bottom: 5rem;
}

@media screen and (min-width: 992px) {
    .UpperPartWrapper {
        padding-bottom: 10rem;
    }
}

@media screen and (min-width: 768px){
    .TherapyPage {
        padding: 0 4rem;
    }

}