.Logo {
    box-sizing: border-box;
    height: 2rem;
}

.Logo .Image {
    height: 100%;
}

@media screen and (min-width: 768px) {
    .Logo {
        height: 2.5rem;
    }
}