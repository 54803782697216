.DiagnosisItem {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.DiagnosisCode {
    color: #00B2B1;
}

.IconStyle {
    width: 24px;
    height: 24px;
    cursor: pointer;
}