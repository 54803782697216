.ProgressWrap {
    display: flex;
    position: relative;
    margin-top: 30px;
    margin-left: 16px;
    width: fit-content;
}

.LevelWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
}

.PiggyIconStyle {
    height: 42px;
    width: 42px;
    color: white;
    border-radius: 50%;
    padding: 6px;
    border: 1px solid gold;
    background-color: #FFAE00;
}

.PiggyIconStyleGray {
    height: 42px;
    width: 42px;
    color: white;
    border-radius: 50%;
    padding: 6px;
    border: 1px solid gray;
    background-color: gray;
}

.LevelLabelWrapper {
    display: flex;
    justify-content: space-between;
    width: 500px;
    margin-top: 15px;
}

.LevelLabelText {
    font-weight: bold;
    color: #8B8C8F;
}