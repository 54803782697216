.ArticleInformationModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.Spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 3rem auto;
}