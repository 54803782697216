.Loyalty {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    background-color: #FFAE00;
    color: white;
    padding: 0.375rem;
    border-radius: 6px;
    min-width: 25%;
    justify-content: space-evenly;
}

.IconStyle {
    height: 100%;
}

.VerticalLine {
    border-right: 1px solid white;
    height: 1rem;
}

.LoyaltyPoints {
    font-size: 1rem;
}