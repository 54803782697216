.QuantityButton {
    height: 24px;
    width: 24px;
    font-size: 22px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.QuantityInput {
    height:34px !important;
    width: 34px !important;
    text-align: center;
    padding: 3px !important;
    border: 1px solid #00B2B1 !important;
}

/* uklanjanje strelica iz number input fielda */
/* Chrome, Safari, Edge, Opera */
.QuantityInput::-webkit-outer-spin-button,
.QuantityInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.QuantityInput[type=number] {
    -moz-appearance: textfield;
}

.SubstituteArticleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DropDownButton {
    color: #00B2B1 !important;
    cursor: pointer;
    background-color: transparent!important;
    border: none !important;
}

.DropDownButton:hover,
.DropDownButton:active {
    color: #1e9898;
    background-color: transparent;
}

.DropDownButton:active,
.DropDownButton:focus-visible {
    background-color: transparent;
    color: #1e9898;
}

.SubstituteArticle {
    color: #00B2B1;
    cursor: pointer;
}

.SubstituteArticle:hover {
    color: #1e9898;
}

.Modal {
    min-width: 30%;
}

.ModalFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.ModalFooterNoLoyalty {
    justify-content: space-between;
}
