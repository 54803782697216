.SearchItems {
    overflow-y: auto;
    width: 100%;
    padding-left: .5rem;
}


.ItemActive {
    background: #d3d3d33b;
}

.Item:hover {
    cursor: pointer;
}