.Button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid  #00B2B1;
    border-radius: 3px;
    width: 200px;
}

.BlueButton {
    background-color: #00B2B1;
    color: white;
}

.BlueButton:hover {
    background-color: #1e9898;
    border: 1px solid  #1e9898;
}

.WhiteButton {
    background-color: white;
    color: #00B2B1;
}

.WhiteButton:hover {
    background-color: #1e9898;
    border: 1px solid  #1e9898;
    color: white;
}

@media screen and (min-width: 992px) {
    .Button {
        padding: 16px;
        font-size: 18px;
        width: 250px;
    }
}