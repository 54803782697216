.SearchItem {
    display: flex;
    align-items: center;
    padding: 6px;
    cursor: pointer;
}

.SearchItem:hover {
    background-color: #E2E8EC;
}

.IconWrapper {
    border: 1px solid #00B2B1;
    border-radius: 6px;
    margin-right: 1rem;
}

.IconStyle {
    height: 16px;
    width: 16px;
    color: #0A0C10;
    margin: 6px;
}

.SearchItemText {
    text-align: left;
}