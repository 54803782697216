.DropdownItem {
    display: flex !important;
    align-items: center !important;
}

.IconStyle {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.MenuIcon {
    height: 24px;
    width: 24px;
}