.ArticleGeneralInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    margin: 0 1rem;
    width: auto;
    height: 100%;
    background-color: #fff;
    position: relative;
    padding: 1rem;
}